var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex flex-col" },
    [
      _c("vs-input", {
        attrs: { label: _vm.label },
        on: {
          keyup: function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            ) {
              return null
            }
            return _vm.addChip.apply(null, arguments)
          },
          blur: _vm.addChip
        },
        model: {
          value: _vm.input,
          callback: function($$v) {
            _vm.input = $$v
          },
          expression: "input"
        }
      }),
      _c(
        "div",
        { staticClass: "flex flex-row flex-wrap" },
        _vm._l(_vm.value, function(tag, index) {
          return _c(
            "vs-chip",
            {
              key: tag,
              staticClass: "mt-2 rounded-md chip",
              attrs: { color: "primary", closable: "" },
              on: {
                click: function($event) {
                  return _vm.removeChip(index)
                }
              }
            },
            [_vm._v(" " + _vm._s(tag) + " ")]
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }