<template>
    <div class="flex flex-col">
        <vs-input 
            v-model="input"
            :label="label"
            @keyup.enter="addChip" @blur="addChip" 
        />
        <div class="flex flex-row flex-wrap">
            <vs-chip 
                v-for="(tag, index) in value"
                :key="tag" 
                color="primary" 
                class="mt-2 rounded-md chip" 
                closable 
                @click="removeChip(index)"
            >
                {{ tag }}
            </vs-chip>
        </div>
    </div>
</template>

<script>

export default {
    name: 'tags',
    props: {
        value: {
            type: Array,
            default: () => []
        },
        label: {
            type: String,
        }
    },
    data() {
        return {
            input: undefined
        }
    },
    methods: {
        addChip() {
            if (this.input) {
                this.value.push(this.input);
                this.input = '';
                this.sync();
            }
        },
        removeChip(index)  { 
            this.value.splice(index, 1); 
            this.sync();
        },
        sync() {
            this.$emit('input', this.value)
        }
    }
}
</script>