var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.fixedLanguages
      ? _c(
          "div",
          { staticClass: "w-50 pr-1 mb-4" },
          [
            _c("LabelForm", [
              _vm._v(_vm._s(_vm.$t("shared.localized-content.language")))
            ]),
            _c("div", { staticClass: "flex" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.selectedLanguage,
                      expression: "selectedLanguage"
                    }
                  ],
                  staticClass: "w-full border-grey-light input-modal",
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.selectedLanguage = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    }
                  }
                },
                _vm._l(_vm.missignLanguages, function(language) {
                  return _c(
                    "option",
                    {
                      key: language.acronym,
                      domProps: { value: language.acronym }
                    },
                    [_vm._v(" " + _vm._s(language.name) + " ")]
                  )
                }),
                0
              ),
              _c(
                "button",
                {
                  staticClass: "ml-3 sk-btn sk-btn-primary rounded-lg",
                  attrs: { disabled: _vm.selectedLanguage === null },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.addLanguageFromButton.apply(null, arguments)
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("shared.add")))]
              )
            ])
          ],
          1
        )
      : _vm._e(),
    _c(
      "div",
      { staticClass: "flex flex-wrap" },
      _vm._l(_vm.internalLanguages, function(contentLanguage, index) {
        return _c(
          "div",
          {
            key: contentLanguage.language,
            class:
              "w-full lg:w-" +
              _vm.gridColumns +
              "/12  relative mt-6 lg:mr-6 p-6 border border-grey rounded"
          },
          [
            _c("div", { staticClass: "flex justify-between items-center" }, [
              _c("h5", { staticClass: "mb-0" }, [
                _vm._v(_vm._s(_vm.getLanguageName(contentLanguage.language)))
              ]),
              !_vm.fixedLanguages &&
              _vm.fixedLanguage !== contentLanguage.language
                ? _c(
                    "button",
                    {
                      staticClass:
                        "ml-4 sk-btn sk-btn-small btn-border sk-btn-primary rounded-lg",
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.deleteLanguage(contentLanguage.language)
                        }
                      }
                    },
                    [
                      _c("feather-icon", {
                        staticClass: "align-middle",
                        attrs: {
                          icon: "TrashIcon",
                          svgClasses: "text-primary w-4"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ]),
            _vm._l(_vm.fieldsDefinition, function(field) {
              return _c(
                "div",
                { key: field.name },
                [
                  field.type === "text"
                    ? _c("vs-input", {
                        staticClass: "w-full border-grey-light",
                        attrs: { label: field.label },
                        on: { input: _vm.sync },
                        model: {
                          value: _vm.internalLanguages[index][field.name],
                          callback: function($$v) {
                            _vm.$set(
                              _vm.internalLanguages[index],
                              field.name,
                              $$v
                            )
                          },
                          expression: "internalLanguages[index][field.name]"
                        }
                      })
                    : _vm._e(),
                  field.type === "textarea"
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "vs-component vs-con-input-label vs-input w-full border-grey-light vs-input-primary"
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "vs-input--label",
                              attrs: { for: "" }
                            },
                            [_vm._v(_vm._s(field.label))]
                          ),
                          _c("div", { staticClass: "vs-con-input" }, [
                            _c("textarea", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value:
                                    _vm.internalLanguages[index][field.name],
                                  expression:
                                    "internalLanguages[index][field.name]"
                                }
                              ],
                              staticClass:
                                "w-full h-32 rounded resize-none border-grey-light p-2",
                              domProps: {
                                value: _vm.internalLanguages[index][field.name]
                              },
                              on: {
                                input: [
                                  function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.internalLanguages[index],
                                      field.name,
                                      $event.target.value
                                    )
                                  },
                                  _vm.sync
                                ]
                              }
                            })
                          ])
                        ]
                      )
                    : _vm._e(),
                  field.type === "rich_textarea"
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "vs-component vs-con-input-label vs-input w-full border-grey-light vs-input-primary"
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "vs-input--label",
                              attrs: { for: "" }
                            },
                            [_vm._v(_vm._s(field.label))]
                          ),
                          _c(
                            "div",
                            { staticClass: "vs-con-input" },
                            [
                              _c("editor", {
                                attrs: {
                                  "api-key": _vm.tinyMCEapikey,
                                  init: _vm.editorConfig
                                },
                                on: { input: _vm.sync },
                                model: {
                                  value:
                                    _vm.internalLanguages[index][field.name],
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.internalLanguages[index],
                                      field.name,
                                      $$v
                                    )
                                  },
                                  expression:
                                    "internalLanguages[index][field.name]"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    : _vm._e(),
                  field.type === "tags"
                    ? _c("Tags", {
                        attrs: { label: field.label },
                        model: {
                          value: _vm.internalLanguages[index][field.name],
                          callback: function($$v) {
                            _vm.$set(
                              _vm.internalLanguages[index],
                              field.name,
                              $$v
                            )
                          },
                          expression: "internalLanguages[index][field.name]"
                        }
                      })
                    : _vm._e(),
                  field.maxLength
                    ? _c("CharCounter", {
                        attrs: {
                          maxLength: field.maxLength,
                          item: _vm.internalLanguages[index][field.name] || ""
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            })
          ],
          2
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }